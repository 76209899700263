import { useState } from 'react';
import './App.css';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Layout, Input, Button, Divider } from 'antd';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Route, Link, Routes,Outlet } from 'react-router-dom';
import QuizComponent  from './quiz'
import Countdown  from './countdown'
const { Header, Content, Footer } = Layout;

function App() {

  return (

    <div className="App">
      
      <Routes>
        <Route path="/" element={<LayoutApp />} />
        <Route index element={<LayoutApp />} />
        <Route path="quiz" element={<QuizComponent />} />
        <Route path="countdown" element={<Countdown />} />
        <Route path="*" element={<>Vyber si</>} />

</Routes>  
    </div>
  );
}

function LayoutApp(){
  return(
    <Layout>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '90vh' }} >
              <div style={{ width: 'auto', display: 'flex' }}>    
       
           <Link to="/countdown">
            <Button>Odpočet</Button>
            </Link>
            <Divider type="vertical"/>
            <Link to="/quiz">
            <Button>Kvíz</Button>
            </Link>
           
              </div>
              <Outlet />
          </div>
        <Footer style={{ textAlign: 'center' }}>Mloušan design ©2022 </Footer>
      </Layout>
  )
}

export default App;
