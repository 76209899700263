import { Layout, PageHeader, Statistic, Carousel } from 'antd';
const { Countdown } = Statistic;


export default function CountDown(){
    return(
        <div style={{ margin: '0px ', background: '#fff' }}>
        <PageHeader title="Nejvíc se těšíme, až k nám přijde mini Mlinka / Mlouště za:" />
        <Countdown style={{ fontSize: 15 }} value={1730418332000} format="D dní HH:mm:ss" />

      </div>
    )
}