import Quiz from './quiz'
import { Layout, Input, Button, Divider } from 'antd';

const { Header, Content, Footer } = Layout;


export default function QuizComponent(){
    return(
        <>

        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <span style={{ fontSize: 35, color: 'white', fontWeight: 600 }}>
              Kvíz lásky pro Mlásenku
            </span>
          </div>
          <div className="logo" />
        </Header>
        <Content className="site-layout" style={{ padding: '0 15px' }}>
          <div className="site-layout-background" style={{ padding: '35px  0px' }}>
            <Quiz />
          </div>
        </Content>
      </>
    )
}