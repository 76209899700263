import React from 'react'
import { Card, Button, Row, Col, Space, Typography, Divider, Modal, InputNumber, Spin, Result } from 'antd';
import { useCookies } from 'react-cookie';

import { UserOutlined, SolutionOutlined, LoadingOutlined, EditOutlined, EllipsisOutlined, SettingOutlined, BookOutlined } from '@ant-design/icons';
const { Title, Paragraph, Text, Link } = Typography;


const { Meta } = Card;
const antIcon = <LoadingOutlined style={{ fontSize: 84 }} spin />;

const sections = ['Naše poprvé', 'Letem světem', 'Kdy kdo a co', 'Různé', 'Mláska je nejvíc']

const data = [
    { 'question': 'Jak dlouho Mlouš čekal na Tinderu, než musel Mlince napsat sám?', correctAnswer: 2, options: ['1 rok', '2 roky', '3 roky', '4 roky'], section: 0 },
    { 'question': 'Jak zněla první zpráva, kterou Mloušan poslal?', correctAnswer: 1, options: ['Hezký pes, můžu si ho pohladit?', 'Ladíš formu na léto?', 'Bolelo to, když jsi spadla z nebe?', 'Zítra u mě nebo u tebe?'], section: 0 },
    { 'question': 'Kde jsme byli na první výročí?', correctAnswer: 2, options: ['Kino', 'Rudolfinum', 'Národní divadlo', 'Večeře v Hui Fengu'], section: 0 },
    { 'question': 'Co jsme večeřeli na prvním rande?', correctAnswer: 0, options: ['McDonald', 'Čínu', 'Bun bo Nam bo', 'Nic'], section: 0 },
    { 'question': 'Kde jsme byli na našeho prvního Prvního Máje?', correctAnswer: 2, options: ['Doma při lockdownu', 'Ve Sloupně', 'Na Petřínu', 'Na výletu výletu'], section: 0 },

    { 'question': 'Kolik je betonových bobánků u největší sochy u zastávky, kde ses neudržela a musela mi dát první pusu?', isNumeric: true, correctAnswer: 4, section: 0 },

    { 'question': 'Kam byl náš první výlet do zahraničí?', correctAnswer: 3, options: ['Slovensko', 'Německo', 'Slovinsko', 'Rakousko'], section: 1 },
    { 'question': 'Kde jsme byli na výletu na Silvestra 2019?', correctAnswer: 0, options: ['Kutná hora', 'Litomyšl', 'Říp', 'Milešovka'], section: 1 },
    { 'question': 'Kde jsi mi poprvé řekla "lásko" (Muflone)?', correctAnswer: 2, options: ['Na Kubáni', 'Na Hájích u rodičů', 'Na mostě na Chodově', 'Ve Sloupně'], section: 1 },
    { 'question': 'Kde jsme byli na prvním lockdown výletu?', correctAnswer: 0, options: ['Vyhlídka Máj', 'Sv. Jan pod Skalou', 'Ve Sloupně', 'Albertova vyhlídka'], section: 1 },
    { 'question': 'Kolik oken má kostel na jehož věži jsme byli na druhém rande?', isNumeric: true, correctAnswer: 11, section: 1 },

    { 'question': 'Kdo bydlel v K 13 v době kdy jsme spolu začali chodit?', correctAnswer: 3, options: ['Nora', 'Keřík', 'Aleš', 'René'], section: 2 },
    { 'question': 'V jaký den napsal Mlouš na Národní třídě, že Kačenka je nejlepší?', correctAnswer: 1, options: ['24. prosince', '17. listopadu', '1. ledna', '9. září'], section: 2 },
    { 'question': 'Kde jsme měli první rande na symfonii?', correctAnswer: 2, options: ['Rudolfinum', 'Národní opera', 'Doma', 'Letní kino'], section: 2 },
    { 'question': 'Kdo vyhrál minigolf v roce 2019?', correctAnswer: 3, options: ['Nikdo', 'Jeníček', 'Skřítci', 'Nejlepší holka'], section: 2 },

    { 'question': 'Kolik je tučňáků na nábřeží, kde jsme byli na Werichovi nebo na výstavě Pasty Onera?', correctAnswer: 34, isNumeric: true, section: 2 },

    { 'question': 'Jaký kus nábytku jsme spolu montovali jako první?', correctAnswer: 1, options: ['Stůl v Krátké', 'Mláseny skříňu', 'Kalax', 'Botník'], section: 3 },
    { 'question': 'Kde jsme byli na večeři v noc, kdy jsi poprvé spala v Kratký 13?', correctAnswer: 0, options: ['Tiskárna', 'U Slámů', 'Vietnam Národní třída', 'McDonald'], section: 3 },
    { 'question': 'Na jakém zápase SKS jsme byli spolu poprvé?', correctAnswer: 2, options: ['Barcelona', 'Teplice', 'Sparta', 'Plzeň'], section: 3 },
    { 'question': 'Kde začalo třetí rande?', correctAnswer: 0, options: ['Bageterie Boulevard', 'Kavárna u DJ Vikiho', 'U Mlouše doma', 'náměstí Míru'], section: 3 },

    { 'question': 'Kolik pater má dům, kde jsme se poprvé setkali?', isNumeric: true, correctAnswer: 1, section: 4 },
    { 'question': 'Jak moc máš ráda pana Mlouše?',  correctAnswer: 3, options: ['8 tuze', '9 tuze', '10 tuze', '11 tuze'], section: 4, isNextTheBigQuestion: true },


]

const buttonStyles = {
    'regular': null,
    'incorrect': { backgroundColor: 'rgb(255, 171, 171)', color: 'rgb(25, 25, 32)', fontWeight: 600, borderWidth: 0 },
    'correct': { backgroundColor: 'rgb(72, 251, 72)', color: 'white', fontWeight: 800, borderWidth: 0 }
}

function QuestionCard(props) {
    const { questionData, nextQuestion } = props
    const [answer, setAnswer] = React.useState(null)
    const [modalVisible, setModalVisible] = React.useState(false)

    const onAnswer = (idx) => {
        console.log(`answer ${idx}`);
        if (idx == null) { return }
        setAnswer(idx)
        setModalVisible(true)
        const timeout = questionData.isNextTheBigQuestion && idx == questionData.correctAnswer ? 1000000 : 2500
        // console.log('this timeout', timeout)
        setTimeout(() => {

            setModalVisible(false);
            if (idx == questionData.correctAnswer) {
                // console.log('correct answer!')
                if (!questionData.isNextTheBigQuestion) {
                    setAnswer(null)
                    nextQuestion()
                }
            }
            else {
                console.log('wrong')
            }
        }, timeout);
    }


    const isCorrectAnswer = answer === questionData.correctAnswer || false
    const modalStyle = isCorrectAnswer && questionData.isNextTheBigQuestion ? { top: 0 } : {}
    return (<><Space direction="vertical" size="small">
        <div className='question-text'>
            <Divider orientation='left'>{sections[questionData.section]}</Divider>
            <div style={{ padding: '25px 25px' }}>
                {questionData.question}
            </div>
        </div>

        <br />
        <div className='buttons-container'>
            {!questionData.isNumeric && questionData.options.map((el, idx) => {
                const buttonType = answer !== idx ? 'regular' : answer == questionData.correctAnswer ? 'correct' : 'incorrect'
                // console.log(idx, answer, buttonType)
                return (
                    <Button key={idx} style={{ width: '90%', height: 50, ...buttonStyles[buttonType] }} onClick={() => { onAnswer(idx) }} type="dashed">{el}</Button>
                )
            }
            )}
            {questionData.isNumeric &&
                <InputNumber min={0} size="large" onChange={(value) => onAnswer(value)} />
            }


        </div>
    </Space >
        <Modal
            title={answer === questionData.correctAnswer ? questionData.isNextTheBigQuestion ? "Načítám poslední otázku" : "Ano!" : "Nopey..."}
            visible={modalVisible}
            footer={null}
            closable={null}
            style={{ ...modalStyle }}
        >
            {isCorrectAnswer && questionData.isNextTheBigQuestion &&
                <div style={{ height: '85vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Spin indicator={antIcon} spinning={true} />
                </div>
            }
            {!questionData.isNextTheBigQuestion &&
                <div style={{ height: '50vh' }}>
                    {isCorrectAnswer ? <Result
                        status="success"
                        title="Ano, Mlasí, to je správně!"
                    />
                        : <Result
                            status="404"
                            title="To není správná odpověď"
                        />}
                </div>
            }


        </Modal>
    </>
    )
}


export default function Quiz() {
    const [cookies, setCookie, removeCookie] = useCookies();
    const [step, setStep] = React.useState(cookies.quizStep || 0)

    const nextQuestion = () => {
        console.log('settting next question')
        console.log(data[step + 1])
        setStep(step + 1)
        setCookie('quizStep', step + 1, { path: '/' });
    }

    return (
        <>
            {/* <div style={{ top: 0, left: 0, zIndex: 999, position: 'absolute' }}>
                <Button onClick={() => { console.log('removing cookie'); removeCookie('quizStep') }}>Del cookies</Button>
            </div> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <QuestionCard questionData={data[step]} nextQuestion={nextQuestion} />
            </div>
        </>
    )
}